const linkResolver = (doc = {}) => {
  if (!doc.type) {
    return linkResolver;
  }

  const linkMap = {
    home_page: "/",
    curriculum_index: "/curriculum/",
    lessons: `/curriculum/lessons/${doc.uid}/`,
    assessments: `/curriculum/assessments/${doc.uid}/`,
    collections: `/curriculum/collections/${doc.uid}/`,
    blog_index: "/blog/",
    blog_posts: `/blog/${doc.uid}/`,
    news_index: "/in-the-news/",
    news_articles: `/in-the-news/${doc.uid}/`,
    video_index: "/videos/",
    videos: `/videos/${doc.uid}/`,
    research_index: "/research/",
    research_articles: `/research/${doc.uid}/`,
    "404_page": `/404/`,
    about_page: "/about/",
    contact_page: "/contact/",
    pages: `/${doc.uid}/`,
  };

  return linkMap[doc.type] || "/";
};

module.exports = linkResolver;
