import React, { useEffect } from "react";
import { useAppState } from "@state/state";
import CORLogo from "@svg/logo-footer.svg";
import { navigate } from "gatsby";
import { usePrismicPreview } from "gatsby-source-prismic";
import linkResolver from "../prismic/linkResolver";
import { Text } from "@ui";

/* eslint react/prop-types: 0 */

const Preview = ({ location }) => {
  const [, dispatch] = useAppState();

  const { previewData, path } = usePrismicPreview(location, {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver,
  });

  useEffect(() => {
    if (previewData && path) {
      window.__PRISMIC_PREVIEW_DATA__ = previewData;
      dispatch({
        type: "setPreviewMode",
        previewMode: true,
      });
      // Set a state value to force Gatsby to prefetch the navigation route
      // so our previewData doesn't get wiped when fetching the page from the CDN.
      navigate(path, { state: { hasPreviewData: true } });
    }
  }, [previewData, path]);

  return (
    <div className="fixed inset-0 flex flex-col justify-center items-center bg-blue z-max">
      <div className="mb-6">
        <CORLogo />
      </div>
      <Text preset="label" className="text-white capitalize">
        Loading preview...
      </Text>
    </div>
  );
};

export default Preview;
